  <mat-drawer #notificationTray
              class="notification-tray mat-elevation-z1"
              [autoFocus]="false"
              [disableClose]="false"
              mode="over">
    <mat-toolbar color="primary">
      <div class="dp-flex-space"></div>

      <button mat-icon-button
              matTooltip="Refresh Notifications"
              (click)="refreshNotifications()">
        <span class="fa fa-sync"></span>
      </button>
      <button mat-icon-button
              matTooltip="Close Notifications"
              (click)="hideNotifications()">
        <span class="fa fa-times"></span>
      </button>
    </mat-toolbar>
    @if((notificationRepo.notifications$ | async).length > 0){
    <ul>
      <li *ngFor="let notification of (notificationRepo.notifications$ | async)"
          class="notification-message"
          [@slideAnimation]="'in'">
        <div>
          <button mat-button
                  matTooltip="Dismiss Notification"
                  matTooltipPosition="right"
                  color="warn"
                  class="notification-remove"
                  (click)="notificationRepo.dismissNotification(notification)">
            <span class="fa fa-times"></span>
          </button>
        </div>

        <ng-container [ngSwitch]="notification.messageFormat">
          <depot-notification-email *ngSwitchCase="messageFormat.O365Email"
                                    [notification]="notification"
                                    [notificationDrawer]="notificationTray"></depot-notification-email>
          <depot-notification-dealer-return *ngSwitchCase="messageFormat.DealerReturn"
                                            [notification]="notification"
                                            [notificationDrawer]="notificationTray">
          </depot-notification-dealer-return>
          <div *ngSwitchDefault>{{notification.messageData}}</div>
        </ng-container>
        <div class="notification-date">
          {{notification.createdDate | utcdate:short}}
        </div>
      </li>
    </ul>
    }@else {
    <div style="padding: 10px; text-align: center;">
      No notifications
    </div>
    }

  </mat-drawer>
